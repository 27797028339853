








































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsRadioGroup from '@/components/VsRadioGroup/Index.vue'
import VsCustomFieldsModal from '@/modules/lists/components/VsCustomFieldsModal/Index.vue'
import VsCustomFieldCard from '@/modules/lists/components/VsCustomFieldCard/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import { createList } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    CustomFieldTypeEnum,
    CustomFieldValidationMethodEnum,
} from '@/utils/customFields'
import { AppModule } from '@/store/modules/app'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import { UserModule } from '@/store/modules/user'
import axios from 'axios'

@Component({
    name: 'CreateList',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsRadioGroup,
        VsCustomFieldsModal,
        VsCustomFieldCard,
        VsPreviewModal,
        VsFormSection,
        VsFormGroup,
    },
})
export default class extends Vue {
    private name = ''
    private uniqueField = 'email'
    private subscriptionMethod: 'double' | 'single' = 'double'
    private suppressionList = 'global'
    private createdListId = null
    private customField = null
    private loading = false
    $refs: any

    get user () {
        return UserModule.user
    }

    get userCompleteName () {
        return UserModule.userCompleteName()
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get optinSubject () {
        return AppModule.optinSubject
    }

    get hasCrmRecipientEnabled () {
        return this.user.configuration.rules.crmRecipientEnabled
    }

    private async submit () {
        if (this.uniqueField === 'other' && !this.customField) {
            this.$root.$vsToast({
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
                heading: 'Devi aggiungere un campo',
            })
            return
        }
        try {
            this.loading = true
            const resp = await createList({
                name: this.name,
                opt_in: {
                    mode: this.subscriptionMethod,
                },
                opt_out: {
                    add_to_global_suppression: this.suppressionList === 'global',
                    mode: 'confirm',
                },
                custom_fields: this.generateCustomFIelds(),
            })
            this.createdListId = resp.data
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403 && e.response.data.message === 'You\'ve reached the limit of recipients in your account') {
                    this.$root.$vsToast({
                        timeout: 3000,
                        aspect: VsToastAspectEnum.alert,
                        heading: 'Hai raggiunto il limite massimo di liste creabili',
                    })
                    this.loading = false
                    return
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
                heading: 'Errore durante la creazione della lista',
            })
            console.log(e)
        }
        this.loading = false
    }

    private generateCustomFIelds (): any [] {
        const custom_fields: any[] = [
            this.createCfEmail(this.uniqueField === 'email'),
            this.createCfMobile(this.uniqueField === 'mobile'),
        ]

        if (this.uniqueField === 'other') {
            custom_fields.push(this.customField)
        }

        return custom_fields
    }

    private createCfEmail (isUnique: boolean) {
        return {
            name: 'Indirizzo Email',
            type: CustomFieldTypeEnum.email,
            global: false,
            validation: {
                method: CustomFieldValidationMethodEnum.emailAddress,
                unique: isUnique,
                required: isUnique,
            },
        }
    }

    private createCfMobile (isUnique: boolean) {
        return {
            name: 'Telefono Cellulare Principale',
            type: CustomFieldTypeEnum.mobile,
            global: false,
            validation: {
                method: CustomFieldValidationMethodEnum.mobile,
                unique: isUnique,
                required: isUnique,
            },
        }
    }

    private setCustomField (customField: any) {
        this.customField = customField
        this.$refs.vsCustomFieldsModal.closeModal()
    }

    private openCustomFieldModal () {
        this.$refs.vsCustomFieldsModal.openModal({
            unique: true,
            showEmail: false,
            showMobile: false,
        })
    }

    private async openPreview () {
        const emailParams = {
            subject: this.optinSubject,
            from_email: this.user.email,
            from_name: `${this.userCompleteName}`,
            id: 'optin',
        }

        this.$refs.vsPreviewModal.openModalNew(emailParams, 'email')
    }
}
